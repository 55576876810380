import React, { useState, useEffect, useCallback } from 'react';
import request from 'superagent';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import TalkeKampagneImage from './TalkeKampagne.png';
import ChessKnightImage from './chess-knight-alt-light.svg';
import { Grid, Button, Dropdown, Container } from 'semantic-ui-react';
import { concat, filter, map, slice, uniq } from 'lodash';
import { useLocation, Link } from 'react-router-dom';
import TalkeTeam from './Talke_HR_Team.jpg';

const messages = defineMessages({
  workingArea: {
    id: 'Working area',
    defaultMessage: 'Working area',
  },
  location: {
    id: 'Workplace Location',
    defaultMessage: 'Location',
  },
  entryLevels: {
    id: 'Entry levels',
    defaultMessage: 'Entry levels',
  },
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
});

const useQuery = () => new URLSearchParams(useLocation().search);

const JobListingView = ({ intl }) => {
  const query = useQuery();
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(6);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [workingArea, setWorkingArea] = useState(
    query.get('workingArea') || '',
  );
  const [location, setLocation] = useState(query.get('location') || '');
  const [entryLevel, setEntryLevel] = useState(query.get('entryLevel') || '');
  const [workingAreaOptions, setWorkingAreaOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [entryLevelOptions, setEntryLevelOptions] = useState([]);
  const addCount = () => {
    setCount(count + 6);
  };
  const filterJobs = useCallback(
    jobs => {
      setCount(6);
      setFilteredJobs(
        filter(
          jobs,
          job =>
            (workingArea === '' ||
              job.workingArea.indexOf(workingArea) !== -1) &&
            (entryLevel === '' || job.entryLevel.indexOf(entryLevel) !== -1) &&
            (location === '' || job.location.indexOf(location) !== -1),
        ),
      );
    },
    [entryLevel, location, workingArea],
  );

  const resetFilterJobs = jobs => {
    setFilteredJobs(jobs);
  };

  useEffect(() => {
    if (!loaded) {
      request
        .get('https://talke.dvinci.de/portal/relaunch/jobPublication/list.json')
        .set('Accept', 'application/json')
        .then(res => {
          setWorkingAreaOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.categories)),
                j => j.name,
              ),
            ),
          );
          setLocationOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.locations)),
                j => j.name,
              ),
            ),
          );
          setEntryLevelOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.targetGroups)),
                j => j.name,
              ),
            ),
          );
          const newJobs = map(res.body, i => ({
            id: i.id,
            position: i.position,
            workingArea: map(i.jobOpening.categories, j => j.name),
            location: map(i.jobOpening.locations, j => j.name),
            entryLevel: map(i.jobOpening.targetGroups, j => j.name),
            date: i.jobOpening.earliestEntryDate,
          }));
          setJobs(newJobs);
          filterJobs(newJobs);
          setLoaded(true);
        });
    }
  }, [entryLevel, filterJobs, jobs, loaded, query]);

  return (
    <Container className="job-offers-view">
      <div className="block hero title">
        <div className="block-inner-wrapper full-width">
          <h2>
            <span>Stellenangebote</span>
          </h2>
          <div className="hero-image-container">
            <img
              className="hero-image"
              src={TalkeKampagneImage}
              alt="TalkeKampagneImage"
            ></img>
          </div>
          <div className="hero-body">
            <h1>Jobs mit Perspektiven</h1>
            <p>
              Sie übernehmen gerne Verantwortung und schätzen die Zusammenarbeit
              im Team? Bei uns finden Sie vielfältige Aufgaben in einem
              kollegialen Umfeld. Klingt genau richtig? Dann werfen Sie doch
              einen Blick auf unsere freien Stellen.
            </p>
            <br />
            <p>Wir freuen uns auf Ihre Bewerbung!</p>
          </div>
        </div>
      </div>
      <Container className="job-filter-block block">
        <Grid className="full-width">
          <Grid.Row className="filters">
            <h2>Jobfinder</h2>
          </Grid.Row>
          <Grid.Row columns={4} className="filters">
            <Grid.Column mobile={12} tablet={3} computer={3}>
              <Dropdown
                text={
                  workingArea
                    ? workingArea
                    : intl.formatMessage(messages.workingArea)
                }
              >
                <Dropdown.Menu>
                  {workingAreaOptions.map(item => (
                    <Dropdown.Item
                      text={item}
                      onClick={() => setWorkingArea(item)}
                    />
                  ))}
                  <Dropdown.Item
                    text={intl.formatMessage(messages.all)}
                    onClick={() => setWorkingArea('')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={3} computer={3}>
              <Dropdown
                text={
                  location ? location : intl.formatMessage(messages.location)
                }
              >
                <Dropdown.Menu>
                  {locationOptions.map(item => (
                    <Dropdown.Item
                      text={item}
                      onClick={() => setLocation(item)}
                    />
                  ))}
                  <Dropdown.Item
                    text={intl.formatMessage(messages.all)}
                    onClick={() => setLocation('')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={3} computer={3}>
              <Dropdown
                text={
                  entryLevel
                    ? entryLevel
                    : intl.formatMessage(messages.entryLevels)
                }
              >
                <Dropdown.Menu>
                  {entryLevelOptions.map(item => (
                    <Dropdown.Item
                      text={item}
                      onClick={() => setEntryLevel(item)}
                    />
                  ))}
                  <Dropdown.Item
                    text={intl.formatMessage(messages.all)}
                    onClick={() => setEntryLevel('')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={3} computer={3}>
              <Button circular secondary onClick={() => filterJobs(jobs)}>
                <FormattedMessage
                  id="Find job now"
                  defaultMessage="Find job now"
                />
              </Button>
            </Grid.Column>
          </Grid.Row>
          {loaded && filteredJobs.length === 0 && count > 0 && (
            <Grid.Row columns={1} centered className="no-jobs">
              <Grid.Column width={12} textAlign="center">
                <p>
                  <b>
                    Entschuldigung, zu dieser Auswahl können wir Ihnen aktuell
                    keine offenen Stellen anbieten. <br />
                    Aber Schauen Sie doch gerne einmal in die{' '}
                    <button
                      onClick={() => {
                        setWorkingArea('');
                        setLocation('');
                        setEntryLevel('');
                        resetFilterJobs(jobs);
                      }}
                    >
                      Gesamtübersicht unserer Stellenangebote
                    </button>
                  </b>
                </p>
              </Grid.Column>
            </Grid.Row>
          )}
          {filteredJobs.length > 0 ? (
            <Grid.Row className="jobs" stretched columns={3}>
              {map(slice(filteredJobs, 0, count), job => (
                <Grid.Column key={job.id} mobile={12} tablet={6} computer={4}>
                  <div className="job">
                    <h2>{job.position}</h2>
                    <p>
                      {job.workingArea.join(', ')} | {job.entryLevel.join(', ')}
                      <br />
                      {job.date} | {job.location.join(', ')}
                    </p>
                    <Link
                      to={`/de/stellenangebote/${job.id}`}
                      className="ui circular button"
                    >
                      <FormattedMessage
                        id="Zum Stellenangebot"
                        defaultMessage="Zum Stellenangebot"
                      />
                    </Link>
                  </div>
                </Grid.Column>
              ))}
            </Grid.Row>
          ) : (
            <></>
          )}
          {filteredJobs.length > count && (
            <Grid.Row className="jobs" columns={1}>
              <Grid.Column width={12}>
                <Button circular onClick={addCount} className="load-more">
                  <FormattedMessage id="load more" defaultMessage="load more" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>

      <div className="block hero blue">
        <div className="block-inner-wrapper full-width">
          <div className="hero-image-container icon">
            <img
              className="hero-image"
              src={ChessKnightImage}
              alt="ChessKnightImage"
            ></img>
          </div>
          <div className="hero-body">
            <h2>Initiativbewerbung</h2>
            <p>
              Sie sind auf der Suche nach einem Job mit Sicherheit in einem
              internationalen Unternehmen, aber die passende Stelle war noch
              nicht dabei? Dann freuen wir uns auf Ihre
            </p>

            <a
              className="ui button fluid circular joblisting"
              href="https://talke.dvinci.de/de/jobs/7/form"
            >
              Initiativbewerbung
            </a>
          </div>
        </div>
      </div>
      <div className="block hero contact static">
        <div className="block-inner-wrapper full-width">
          <div className="hero-image-container container">
            <img
              src={TalkeTeam}
              alt="envelope icon"
              className="hero-image"
            ></img>
          </div>
          <div className="hero-body">
            <h2>
              <FormattedMessage id="Contact" defaultMessage="Contact" />
            </h2>
            <p>
              Wenn Sie sich für eine Stelle in der TALKE-Gruppe interessieren,
              finden Sie hier{' '}
              <Link to="/de/stellenangebote">
                unsere aktuellen Stellenangebote
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default injectIntl(JobListingView);
