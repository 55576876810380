/**
 * Edit Hero block.
 * @module components/manage/Blocks/ContactBlock/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { readAsDataURL } from 'promise-file-reader';
import { Button, Dimmer, Loader, Message, Input } from 'semantic-ui-react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { flattenToAppURL, getBaseUrl } from '@plone/volto/helpers';
import { createContent } from '@plone/volto/actions';
import { Icon, SidebarPortal } from '@plone/volto/components';
import ContactSidebar from '../../manage/Sidebar/ContactSidebar';

import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import aheadSVG from '@plone/volto/icons/ahead.svg';
import imageBlockSVG from './block-image.svg';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  ImageBlockInputPlaceholder: {
    id: 'Browse the site, drop an image, or type an URL',
    defaultMessage: 'Browse the site, drop an image, or type an URL',
  },
});

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);

    this.onUploadImage = this.onUploadImage.bind(this);
    this.state = {
      uploading: false,
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.request.loading &&
      nextProps.request.loaded &&
      this.state.uploading
    ) {
      this.setState({
        uploading: false,
      });
      this.props.onChangeBlock(this.props.block, {
        ...this.props.data,
        url: nextProps.content['@id'],
      });
    }
  }

  /**
   * Upload image handler
   * @method onUploadImage
   * @returns {undefined}
   */
  onUploadImage({ target }) {
    const file = target.files[0];
    this.setState({
      uploading: true,
    });
    readAsDataURL(file).then(data => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(getBaseUrl(this.props.pathname), {
        '@type': 'Image',
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file.name,
        },
      });
    });
  }

  /**
   * Submit url handler
   * @method onSubmitUrl
   * @param {object} e Event
   * @returns {undefined}
   */
  onSubmitUrl = () => {
    this.props.onChangeBlock(this.props.block, {
      ...this.props.data,
      url: this.state.url,
    });
  };

  /**
   * Drop handler
   * @method onDrop
   * @param {array} files File objects
   * @returns {undefined}
   */
  onDrop = file => {
    this.setState({
      uploading: true,
    });
    readAsDataURL(file[0]).then(data => {
      const fields = data.match(/^data:(.*);(.*),(.*)$/);
      this.props.createContent(getBaseUrl(this.props.pathname), {
        '@type': 'Image',
        title: file[0].name,
        image: {
          data: fields[3],
          encoding: fields[2],
          'content-type': fields[1],
          filename: file[0].name,
        },
      });
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    return (
      <div
        className={cx('block hero contact', {
          selected: this.props.selected,
        })}
      >
        {this.props.selected && !!this.props.data.url && (
          <div className="toolbar">
            <Button.Group>
              <Button
                icon
                basic
                onClick={() =>
                  this.props.onChangeBlock(this.props.block, {
                    ...this.props.data,
                    url: '',
                  })
                }
              >
                <Icon name={clearSVG} size="24px" color="#e40166" />
              </Button>
            </Button.Group>
          </div>
        )}
        <div className="block-inner-wrapper full-width">
          {this.props.data.url ? (
            <div
              className={cx('hero-image-container', {
                icon: this.props.data.isIcon,
              })}
            >
              <img
                className="hero-image"
                src={`${flattenToAppURL(
                  this.props.data.url,
                )}/@@images/image/large`}
                alt={this.props.data.alt || ''}
              />
            </div>
          ) : (
            <div className="hero-image-container">
              <Dropzone onDrop={this.onDrop} className="dropzone">
                <Message>
                  {this.state.uploading && (
                    <Dimmer active>
                      <Loader indeterminate>Uploading image</Loader>
                    </Dimmer>
                  )}
                  <center>
                    <img src={imageBlockSVG} alt="Contact Person" />
                    <div className="toolbar-inner">
                      <Button.Group>
                        <Button
                          basic
                          icon
                          onClick={e => {
                            e.stopPropagation();
                            this.props.openObjectBrowser();
                          }}
                        >
                          <Icon name={navTreeSVG} size="24px" />
                        </Button>
                      </Button.Group>
                      <Input
                        onKeyDown={this.onKeyDownVariantMenuForm}
                        onChange={this.onChangeUrl}
                        placeholder={this.props.intl.formatMessage(
                          messages.ImageBlockInputPlaceholder,
                        )}
                        // Prevents propagation to the Dropzone and the opening
                        // of the upload browser dialog
                        onClick={e => e.stopPropagation()}
                      />
                      {this.state.url && (
                        <Button.Group>
                          <Button basic className="cancel">
                            <Icon name={clearSVG} size="30px" />
                          </Button>
                        </Button.Group>
                      )}
                      <Button.Group>
                        <Button basic primary>
                          <Icon name={aheadSVG} size="30px" />
                        </Button>
                      </Button.Group>
                    </div>
                  </center>
                </Message>
              </Dropzone>
            </div>
          )}
          <div className="hero-body">
            <h2>
              <FormattedMessage id="Contact" defaultMessage="Contact" />
            </h2>
            <p>
              <FormattedMessage
                id="Please send your questions or applications to: {mailAddress}"
                defaultMessage="Please send your questions or applications to: {mailAddress}"
                values={{
                  mailAddress: (
                    <a href="mailto:bewerbung@talke.com">bewerbung@talke.com</a>
                  ),
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="If you have any further questions, please do not hesitate to contact us:"
                defaultMessage="If you have any further questions, please do not hesitate to contact us: "
              />
            </p>
            <div className="contact-card">
              <p>{this.props.data.contactPerson} </p>
              <p>{this.props.data.division}</p>
              <p>{this.props.data.phoneNumber}</p>
            </div>
          </div>
        </div>

        <SidebarPortal selected={this.props.selected}>
          <ContactSidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  withObjectBrowser,
  injectIntl,
  connect(
    state => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
