import React, { useState, useEffect } from 'react';
import { Button, Container, Checkbox, Form } from 'semantic-ui-react';
import cookie from 'react-cookie';

/**
 * View Hero block class.
 * @class View
 * @extends Component
 */
const View = props => {
  const [agreedYouTube, setAgreedYouTube] = useState(false);
  const [agreedMatomo, setAgreedMatomo] = useState(false);

  const confirmSelection = () => {
    if (agreedMatomo) {
      cookie.save('confirm_matomo', 'confirmed', {
        path: '/',
      });
      window._paq.push(['setConsentGiven']);
    } else {
      cookie.remove('confirm_matomo', {
        path: '/',
      });
      window._paq.push(['disableCookies']);
    }

    if (agreedYouTube) {
      cookie.save('confirm_youtube', 'confirmed', {
        path: '/',
      });
    } else {
      cookie.remove('confirm_youtube', {
        path: '/',
      });
    }
  };

  useEffect(() => {
    var confirmedYouTube = cookie.load('confirm_youtube');
    var confirmedMatomo = cookie.load('confirm_matomo');
    if (confirmedYouTube === 'confirmed') {
      setAgreedYouTube(true);
    }
    if (confirmedMatomo === 'confirmed') {
      setAgreedMatomo(true);
      window._paq.push(['setConsentGiven']);
    } else {
      window._paq.push(['forgetConsentGiven']);
      window._paq.push(['disableCookies']);
    }
  }, []);

  return (
    <div className="block cookies">
      <Container>
        <Form>
          <Form.Field>
            <Checkbox
              toggle
              label="Einbinden von Youtube Videos"
              onChange={() => setAgreedYouTube(!agreedYouTube)}
              checked={agreedYouTube}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label="Statistik"
              onChange={() => setAgreedMatomo(!agreedMatomo)}
              checked={agreedMatomo}
            />
          </Form.Field>
        </Form>
      </Container>
      <div>
        <Button circular onClick={confirmSelection}>
          Auswahl aktualisieren
        </Button>
      </div>
    </div>
  );
};
export default View;
