/**
 * Hero image block.
 * @module components/manage/Blocks/HeroBlock/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { TextBody } from '@kitconcept/volto-blocks/components';

/**
 * View Hero block class.
 * @class View
 * @extends Component
 */
const View = props => (
  <div
    className={cx('block hero double-text', props.data.color, {
      title: props.data.isTitle,
    })}
  >
    <div className="block-inner-wrapper full-width">
      <div className="hero-body">
        <TextBody {...props} dataName="text-a" />
      </div>
      <div className="hero-body">
        <TextBody {...props} dataName="text-b" />

        {props.data.href && (
          <Link
            className={cx('ui button fluid circular', {
              primary: props.data.redButton,
              'mobile tablet only': props.data.mobileOnlyButton,
            })}
            to={props.data.href}
          >
            {props.data.linkTitle}
          </Link>
        )}
      </div>
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
