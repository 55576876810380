/**
 * Hero image block.
 * @module components/manage/Blocks/HeroBlock/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

/**
 * View Hero block class.
 * @class View
 * @extends Component
 */
const View = props => (
  <div className="block benefits">
    <Grid centered className="full-width">
      <Grid.Row>
        <Grid.Column computer={8} tablet={12}>
          <h2>Vorteile bei TALKE</h2>{' '}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <i className="fal fa-user-shield"></i>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Optimale Arbeitssicherheit entsteht im Zusammenspiel von
            Unternehmenskultur, individuellen Fähigkeiten und Equipment. Wir
            verbinden die Norm mit mehr als 70 Jahren Erfahrung in der
            Chemielogistik!
          </p>
        </Grid.Column>
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <i className="fal fa-stethoscope"></i>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Bei TALKE profitieren Sie von umfangreichen Gesundheitsangeboten und
            regelmäßigen Gesundheitschecks.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <i className="fal fa-heartbeat"></i>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Wir unterstützen Sie mit Zuschüssen und bieten Ihnen eine attraktive
            betriebliche Altersvorsorge, denn Ihre Vorsorge liegt uns am Herzen.
          </p>
        </Grid.Column>
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <i className="fal fa-handshake"></i>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Erfolgreiche Chemielogistik beherrscht Standards und kann schnell
            auf spezielle Situationen umschalten – mit allen Beteiligten. Echtes
            Teamspiel ist für uns daher nicht nur so ein Wort!
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <i className="fal fa-user-astronaut"></i>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Ihre Einarbeitung stimmen wir mit Ihnen individuell ab. Je nach
            Abteilung und Einstiegslevel stellen wir Ihnen auch Paten*innen zur
            Seite, um Ihre ersten Tage bei TALKE so aufschlussreich wie möglich
            zu gestalten.
          </p>
        </Grid.Column>
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <i className="fal fa-user-chart"></i>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Wir gehen mit der Zeit und unsere Mannschaft mit uns – mit
            individuellen Entwicklungs- und Weiterbildungsmöglichkeiten.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {/* Mobiles Arbeiten */}
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="laptop-house"
          >
            <path d="M624,416H592V288c0-17.68-12.9-32-28.81-32H332.73c-15.91,0-28.8,14.32-28.8,32V416h-32a16,16,0,0,0-16,16v37.34A42.82,42.82,0,0,0,298.53,512H597.39A42.83,42.83,0,0,0,640,469.33V432A16,16,0,0,0,624,416ZM335.93,288H560V416h-224ZM608,469.26A10.76,10.76,0,0,1,597.39,480H298.64a10.78,10.78,0,0,1-10.72-10.67V448H608ZM197.28,160A21.41,21.41,0,0,0,175.9,181.3v85.35A21.42,21.42,0,0,0,197.28,288h74.64a64.32,64.32,0,0,1,32-56V181.3A21.44,21.44,0,0,0,282.59,160Zm74.64,96h-64V192h64Zm-176,112V168a18.8,18.8,0,0,0-.81-4L239.91,37.22l144,126V224h32V191.25L453.34,224h20a15.71,15.71,0,0,0,6.2-12.33A18.31,18.31,0,0,0,474.49,200L416,148.72V48a16,16,0,0,0-32,0v72.75L250.45,4a16,16,0,0,0-21.07,0L5.33,200A18.24,18.24,0,0,0,0,211.89a16,16,0,0,0,16,16,18.62,18.62,0,0,0,10.4-3.83l37.47-32.82V368a48.1,48.1,0,0,0,48,48h112V384h-112A16,16,0,0,1,95.88,368Z" />
          </svg>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Mobiles Arbeiten bei TALKE ermöglicht Ihnen, Arbeit und Leben ins
            Gleichgewicht zu bringen. In Abstimmung mit der Führungskraft kann
            bei uns jede*r Mitarbeiter*in mobil arbeiten, sofern dies mit den
            Aufgabe vereinbar ist.
          </p>
        </Grid.Column>
        {/* Fahrrad Leasing */}
        <Grid.Column computer={1} tablet={2} mobile={12} textAlign={'center'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="laptop-house bike"
          >
            <path d="M392 32a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm0 80A56 56 0 1 0 392 0a56 56 0 1 0 0 112zM224 384A96 96 0 1 1 32 384a96 96 0 1 1 192 0zM128 256a128 128 0 1 0 0 256 128 128 0 1 0 0-256zM608 384a96 96 0 1 1 -192 0 96 96 0 1 1 192 0zM512 256a128 128 0 1 0 0 256 128 128 0 1 0 0-256zM309.1 166.4c6.1-5.2 15.1-5.1 21.1 .3l59.1 53.2c2.9 2.6 6.8 4.1 10.7 4.1h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H406.1l-54.5-49.1c-17.9-16.1-45-16.5-63.3-.8l-59.1 50.6c-23.9 20.5-21.9 58.1 4 76L320 328.4V416c0 8.8 7.2 16 16 16s16-7.2 16-16V320c0-5.3-2.6-10.2-6.9-13.2l-93.7-64.4c-8.7-6-9.3-18.5-1.3-25.3l59.1-50.6z" />
          </svg>
        </Grid.Column>
        <Grid.Column computer={3} tablet={10} mobile={12}>
          <p>
            Gesund, umweltschonend und steuersparend unterwegs mit dem TALKE
            Fahrradleasing. Wählen Sie aus einem großen Angebot ihr E-Bike,
            Fahrrad oder Lastenrad und leasen Sie einfach und unkompliziert.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
