import React, { useState, useEffect } from 'react';
import request from 'superagent';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { Grid, Button, Dropdown } from 'semantic-ui-react';
import { concat, map, uniq } from 'lodash';

const messages = defineMessages({
  workingArea: {
    id: 'Working area',
    defaultMessage: 'Working area',
  },
  location: {
    id: 'Workplace Location',
    defaultMessage: 'Location',
  },
  entryLevels: {
    id: 'Entry levels',
    defaultMessage: 'Entry levels',
  },
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
});

const View = ({ intl }) => {
  const [loaded, setLoaded] = useState(false);
  const [workingArea, setWorkingArea] = useState('');
  const [location, setLocation] = useState('');
  const [entryLevel, setEntryLevel] = useState('');
  const [workingAreaOptions, setWorkingAreaOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [entryLevelOptions, setEntryLevelOptions] = useState([]);
  const filterJobs = () => {
    document.location.href = `/de/stellenangebote?location=${location}&entryLevel=${entryLevel}&workingArea=${workingArea}`;
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      request
        .get('https://talke.dvinci.de/portal/relaunch/jobPublication/list.json')
        .set('Accept', 'application/json')
        .then(res => {
          setWorkingAreaOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.categories)),
                j => j.name,
              ),
            ),
          );
          setLocationOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.locations)),
                j => j.name,
              ),
            ),
          );
          setEntryLevelOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.targetGroups)),
                j => j.name,
              ),
            ),
          );
        });
    }
  }, [loaded]);

  return (
    <div className="job-filter-block block">
      <Grid className="full-width">
        <Grid.Row>
          <h2>Jobfinder</h2>
        </Grid.Row>
        <Grid.Row columns={4} className="filters">
          <Grid.Column mobile={12} computer={3}>
            <Dropdown
              text={
                workingArea
                  ? workingArea
                  : intl.formatMessage(messages.workingArea)
              }
            >
              <Dropdown.Menu>
                {workingAreaOptions.map(item => (
                  <Dropdown.Item
                    text={item}
                    onClick={() => setWorkingArea(item)}
                  />
                ))}
                <Dropdown.Item
                  text={intl.formatMessage(messages.all)}
                  onClick={() => setWorkingArea('')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={12} computer={3}>
            <Dropdown
              text={location ? location : intl.formatMessage(messages.location)}
            >
              <Dropdown.Menu>
                <div className="dropdown-scroller">
                  {locationOptions.map(item => (
                    <Dropdown.Item
                      text={item}
                      onClick={() => setLocation(item)}
                    />
                  ))}
                  <Dropdown.Item
                    text={intl.formatMessage(messages.all)}
                    onClick={() => setLocation('')}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={12} computer={3}>
            <Dropdown
              text={
                entryLevel
                  ? entryLevel
                  : intl.formatMessage(messages.entryLevels)
              }
            >
              <Dropdown.Menu>
                {entryLevelOptions.map(item => (
                  <Dropdown.Item
                    text={item}
                    onClick={() => setEntryLevel(item)}
                  />
                ))}
                <Dropdown.Item
                  text={intl.formatMessage(messages.all)}
                  onClick={() => setEntryLevel('')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={12} computer={3}>
            <Button circular secondary onClick={filterJobs}>
              <FormattedMessage
                id="Find job now"
                defaultMessage="Find job now"
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default injectIntl(View);
