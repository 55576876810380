import React from 'react';

import View from './View.jsx';

const Edit = props => {
  if (__SERVER__) {
    return <div />;
  } else {
    return (
      <>
        <View />
      </>
    );
  }
};

export default Edit;
