import React, { useState, useEffect } from 'react';
import { Button, Modal, Checkbox, Form } from 'semantic-ui-react';
import cookie from 'react-cookie';

const CookieModal = props => {
  const [showConfirmModal, setShowConfirmModal] = useState(true);
  const [agreedYouTube, setAgreedYouTube] = useState(false);
  const [agreedMatomo, setAgreedMatomo] = useState(true);

  const confirmSelection = () => {
    if (agreedMatomo) {
      cookie.save('confirm_matomo', 'confirmed', {
        path: '/',
        expires: new Date((2 ** 31 - 1) * 1000),
      });
      window._paq.push(['setConsentGiven']);
    } else {
      window._paq.push(['forgetConsentGiven']);
      window._paq.push(['disableCookies']);
    }

    if (agreedYouTube) {
      cookie.save('confirm_youtube', 'confirmed', {
        path: '/',
        expires: new Date((2 ** 31 - 1) * 1000),
      });
    } else {
      cookie.remove('confirm_youtube', {
        path: '/',
        expires: new Date((2 ** 31 - 1) * 1000),
      });
    }

    setShowConfirmModal(false);
  };

  const confirmAll = () => {
    cookie.save('confirm_matomo', 'confirmed', {
      path: '/',
      expires: new Date((2 ** 31 - 1) * 1000),
    });
    window._paq.push(['setConsentGiven']);
    cookie.save('confirm_youtube', 'confirmed', {
      path: '/',
      expires: new Date((2 ** 31 - 1) * 1000),
    });

    setShowConfirmModal(false);
  };

  useEffect(() => {
    var confirmedYouTube = cookie.load('confirm_youtube');
    var confirmedMatomo = cookie.load('confirm_matomo');
    if (confirmedYouTube === 'confirmed') {
      setAgreedYouTube(true);
    }
    if (confirmedMatomo === 'confirmed') {
      setAgreedMatomo(true);
      setShowConfirmModal(false);
      window._paq.push(['setConsentGiven']);
    } else {
      window._paq.push(['forgetConsentGiven']);
    }

    if (window.location.href.includes('datenschutzerklaerung')) {
      setShowConfirmModal(false);
    }
  }, []);

  return (
    <Modal
      id="question-landing"
      open={showConfirmModal}
      dimmer="inverted"
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <div>
          <h3>Cookies</h3>
          <p className="cookie-text">
            Diese Seiten verwenden Cookies und Matomo für anonymisierte
            Webanalysen. So können wir die Webseite optimal gestalten und
            fortlaufend verbessern. Mehr Informationen und
            Opt-Out-Möglichkeiten, finden Sie in unserer{' '}
            <a href="/de/datenschutzerklaerung">Datenschutzerklärung</a>.
          </p>
        </div>
        <Form>
          <Form.Field>
            <Checkbox
              toggle
              label="Einbinden von YouTube Videos"
              onChange={() => setAgreedYouTube(!agreedYouTube)}
              checked={agreedYouTube}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label="Statistik"
              onChange={() => setAgreedMatomo(!agreedMatomo)}
              checked={agreedMatomo}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button circular onClick={confirmSelection}>
          Auswahl zustimmen und fortfahren
        </Button>
        <Button circular className="red" onClick={confirmAll}>
          Allem zustimmen und fortfahren
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CookieModal;
