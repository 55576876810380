import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Form, Grid } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { CheckboxWidget, Icon, TextWidget } from '@plone/volto/components';
import { Slider } from 'react-semantic-ui-range';
import { flattenToAppURL } from '@plone/volto/helpers';
import { settings } from '~/config';
import { ColorBlock } from '../../../helpers';

import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  Icon: {
    id: 'Show Image as Miniature Icon',
    defaultMessage: 'Show Image as Miniature Icon',
  },
});

const JobOffersSidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [alt] = useState(data.alt || '');

  const sliderSettings = {
    start: data.jobAmount || 4,
    min: 1,
    max: 6,
    step: 1,
    onChange: value => {
      onChangeBlock(block, {
        ...data,
        jobAmount: value,
      });
    },
  };

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Image" defaultMessage="Image" />
        </h2>
      </header>
      {!data.url && (
        <>
          <Segment className="sidebar-metadata-container" secondary>
            <FormattedMessage
              id="No image selected"
              defaultMessage="No image selected"
            />
            <Icon name={imageSVG} size="100px" color="#b8c6c8" />
          </Segment>
        </>
      )}
      {data.url && (
        <>
          <Segment className="sidebar-metadata-container" secondary>
            {data.url.split('/').slice(-1)[0]}
            {data.url.includes(settings.apiPath) && (
              <img
                src={`${flattenToAppURL(data.url)}/@@images/image/mini`}
                alt={alt}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <img src={data.url} alt={alt} style={{ width: '50%' }} />
            )}
          </Segment>
          <Segment className="form sidebar-image-data">
            {data.url.includes(settings.apiPath) && (
              <TextWidget
                id="Origin"
                title={intl.formatMessage(messages.Origin)}
                required={false}
                value={data.url.split('/').slice(-1)[0]}
                icon={navTreeSVG}
                iconAction={() => openObjectBrowser()}
                onChange={() => {}}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <TextWidget
                id="external"
                title={intl.formatMessage(messages.externalURL)}
                required={false}
                value={data.url}
                icon={clearSVG}
                iconAction={() =>
                  onChangeBlock(block, {
                    ...data,
                    url: '',
                  })
                }
                onChange={() => {}}
              />
            )}
            <CheckboxWidget
              id="isIcon"
              title={intl.formatMessage(messages.Icon)}
              value={data.isIcon ? data.isIcon : false}
              onChange={(name, value) => {
                onChangeBlock(block, {
                  ...data,
                  isIcon: value,
                });
              }}
            />
          </Segment>
        </>
      )}
      <Segment className="form">
        <header className="header pulled">
          <h2>
            <FormattedMessage id="Layout" defaultMessage="Layout" />
          </h2>
        </header>
        <Form.Field inline required={required} className="help">
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                <div className="wrapper">
                  <label htmlFor="field-align">
                    <FormattedMessage
                      id="Background Color"
                      defaultMessage=" Background Color:"
                    />
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column width="8" className="align-tools">
                <ColorBlock
                  color={data.color}
                  onChangeBlock={onChangeBlock}
                  data={data}
                  block={block}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <p className="help">
                  <FormattedMessage
                    id="Choose a background color for the Block"
                    defaultMessage="Choose a background color for the Block"
                  />
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
        <Form.Field inline required={required}>
          <Grid>
            <Grid.Column width={4}>
              <div className="wrapper">
                <label>
                  <FormattedMessage
                    id="Shown Jobs"
                    defaultMessage="Shown Jobs: "
                  />
                  <b>{data.jobAmount || 4}</b>
                </label>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <Slider
                value={data.jobAmount}
                color="black"
                settings={sliderSettings}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Segment>
    </Segment.Group>
  );
};

JobOffersSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(JobOffersSidebar);
