import React from 'react';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { TextWidget } from '@plone/volto/components';

import AktuellesData from './AktuellesData';

const BemerkungSidebar = props => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>Bild-Karussel</h2>
      </header>

      <Segment className="form sidebar-image-data">
        <TextWidget
          id="name"
          title="Titel"
          required={false}
          value={props.data.title}
          onChange={(name, value) => {
            props.onChangeBlock(props.block, {
              ...props.data,
              title: value,
            });
          }}
        />
      </Segment>

      <AktuellesData {...props} />
    </Segment.Group>
  );
};

export default BemerkungSidebar;
