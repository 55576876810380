import React from 'react';
import JobOffer from './View';

const Edit = props => {
  return (
    <>
      <JobOffer />
    </>
  );
};

export default Edit;
