import React, { useState, useEffect, useCallback } from 'react';
import request from 'superagent';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { Grid, Button, Dropdown } from 'semantic-ui-react';
import { concat, filter, map, slice, uniq } from 'lodash';
import { useLocation } from 'react-router-dom';

const messages = defineMessages({
  workingArea: {
    id: 'Working area',
    defaultMessage: 'Working area',
  },
  location: {
    id: 'Workplace Location',
    defaultMessage: 'Location',
  },
  entryLevels: {
    id: 'Entry levels',
    defaultMessage: 'Entry levels',
  },
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
});

const useQuery = () => new URLSearchParams(useLocation().search);

const View = ({ intl }) => {
  const query = useQuery();
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(6);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [workingArea, setWorkingArea] = useState(
    query.get('workingArea') || '',
  );
  const [location, setLocation] = useState(query.get('location') || '');
  const [entryLevel, setEntryLevel] = useState(query.get('entryLevel') || '');
  const [workingAreaOptions, setWorkingAreaOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [entryLevelOptions, setEntryLevelOptions] = useState([]);
  const addCount = () => {
    setCount(count + 6);
  };
  const filterJobs = useCallback(
    jobs => {
      setCount(6);
      setFilteredJobs(
        filter(
          jobs,
          job =>
            (workingArea === '' ||
              job.workingArea.indexOf(workingArea) !== -1) &&
            (entryLevel === '' || job.entryLevel.indexOf(entryLevel) !== -1) &&
            (location === '' || job.location.indexOf(location) !== -1),
        ),
      );
    },
    [entryLevel, location, workingArea],
  );

  useEffect(() => {
    if (!loaded) {
      request
        .get('https://talke.dvinci.de/portal/relaunch/jobPublication/list.json')
        .set('Accept', 'application/json')
        .then(res => {
          setWorkingAreaOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.categories)),
                j => j.name,
              ),
            ),
          );
          setLocationOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.locations)),
                j => j.name,
              ),
            ),
          );
          setEntryLevelOptions(
            uniq(
              map(
                concat(...map(res.body, i => i.jobOpening.targetGroups)),
                j => j.name,
              ),
            ),
          );
          const newJobs = map(res.body, i => ({
            jobPublicationURL: i.jobPublicationURL,
            position: i.position,
            workingArea: map(i.jobOpening.categories, j => j.name),
            location: map(i.jobOpening.locations, j => j.name),
            entryLevel: map(i.jobOpening.targetGroups, j => j.name),
            date: i.jobOpening.earliestEntryDate,
            id: i.jobOpening.id,
          }));
          setJobs(newJobs);
          filterJobs(newJobs);
          setLoaded(true);
        });
    }
  }, [entryLevel, filterJobs, jobs, loaded, query]);

  return (
    <div className="job-filter-block block">
      <Grid className="full-width">
        <Grid.Row className="filters">
          <h2>Jobfinder</h2>
        </Grid.Row>
        <Grid.Row columns={4} className="filters">
          <Grid.Column mobile={12} tablet={3} computer={3}>
            <Dropdown
              text={
                workingArea
                  ? workingArea
                  : intl.formatMessage(messages.workingArea)
              }
            >
              <Dropdown.Menu>
                {workingAreaOptions.map(item => (
                  <Dropdown.Item
                    text={item}
                    onClick={() => setWorkingArea(item)}
                  />
                ))}
                <Dropdown.Item
                  text={intl.formatMessage(messages.all)}
                  onClick={() => setWorkingArea('')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={12} tablet={3} computer={3}>
            <Dropdown
              text={location ? location : intl.formatMessage(messages.location)}
            >
              <Dropdown.Menu>
                {locationOptions.map(item => (
                  <Dropdown.Item
                    text={item}
                    onClick={() => setLocation(item)}
                  />
                ))}
                <Dropdown.Item
                  text={intl.formatMessage(messages.all)}
                  onClick={() => setLocation('')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={12} tablet={3} computer={3}>
            <Dropdown
              text={
                entryLevel
                  ? entryLevel
                  : intl.formatMessage(messages.entryLevels)
              }
            >
              <Dropdown.Menu>
                {entryLevelOptions.map(item => (
                  <Dropdown.Item
                    text={item}
                    onClick={() => setEntryLevel(item)}
                  />
                ))}
                <Dropdown.Item
                  text={intl.formatMessage(messages.all)}
                  onClick={() => setEntryLevel('')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={12} tablet={3} computer={3}>
            <Button circular secondary onClick={() => filterJobs(jobs)}>
              <FormattedMessage
                id="Find job now"
                defaultMessage="Find job now"
              />
            </Button>
          </Grid.Column>
        </Grid.Row>
        {filteredJobs.length > 0 && (
          <Grid.Row className="jobs" stretched columns={3}>
            {map(slice(filteredJobs, 0, count), job => (
              <Grid.Column key={job.id} mobile={12} tablet={6} computer={4}>
                <div className="job">
                  <h2>{job.position}</h2>
                  <p>
                    {job.workingArea.join(', ')} | {job.entryLevel.join(', ')}
                    <br />
                    {job.date} | {job.location.join(', ')}
                  </p>
                  <a href={job.id} className="ui circular button">
                    <FormattedMessage
                      id="Zum Stellenangebot"
                      defaultMessage="Zum Stellenangebot"
                    />
                  </a>
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        )}
        {loaded && filteredJobs.length === 0 && count > 0 && (
          <Grid.Row columns={1}>
            <Grid.Column width={12}>
              <p>
                <FormattedMessage
                  id="Zu Ihrer Jobauswahl liegen zur Zeit leider keine Angebote vor."
                  defaultMessage="Zu Ihrer Jobauswahl liegen zur Zeit leider keine Angebote vor."
                />
              </p>
            </Grid.Column>
          </Grid.Row>
        )}
        {filteredJobs.length > count && (
          <Grid.Row columns={1} className="jobs">
            <Grid.Column width={12}>
              <Button circular onClick={addCount} className="load-more">
                <FormattedMessage id="load more" defaultMessage="load more" />
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

export default injectIntl(View);
