/**
 * View contact block.
 * @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

/**
 * jobOffer class.
 * @class View
 * @extends Component
 */
const jobOffer = ({
  title,
  section,
  entranceLevel,
  startDate,
  location,
  type,
  url,
}) => {
  return (
    <div className="job-offer-unit">
      <Link to={url}>{title}</Link>
      <p>
        {`
        ${section.length > 0 ? section.join(',') + '|' : ''}
        ${entranceLevel && entranceLevel + '|'}
        ${startDate != null ? startDate + '|' : ''}
        ${location}
        `}
      </p>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
jobOffer.propTypes = {
  title: PropTypes.string.isRequired,
  section: PropTypes.array,
  entranceLevel: PropTypes.array,
  startDate: PropTypes.string,
  location: PropTypes.array,
  type: PropTypes.string,
};

export default jobOffer;
