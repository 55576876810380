import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { getQueryStringResults } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';

const MeldungenView = props => {
  const dispatch = useDispatch();
  const subrequest = useSelector(
    state => state.querystringsearch.subrequests[props.id],
  );

  useEffect(() => {
    dispatch(
      getQueryStringResults(
        '/',
        {
          b_size: 3,
          fullobjects: 1,
          query: [
            {
              i: 'path',
              o: 'plone.app.querystring.operation.string.absolutePath',
              v: '/de/meldungen/',
            },
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['Document'],
            },
          ],
          sort_on: 'effective',
          sort_order: 'ascending',
        },
        props.id,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="block meldungen">
      <Grid className="full-width">
        <Grid.Row>
          <Grid.Column
            computer={12}
            tablet={12}
            mobile={12}
            textAlign={'center'}
          >
            <a href="de/meldungen">
              <h2 className="meldungen-title"> Meldungen </h2>
            </a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {subrequest?.items.map(item => (
            <Grid.Column
              computer={4}
              tablet={12}
              mobile={12}
              textAlign={'center'}
            >
              {flattenToAppURL(item['@id']) !== '/de/meldungen' && (
                <Link
                  className="meldung card"
                  to={flattenToAppURL(item['@id'])}
                >
                  <div className="meldungen-image-wrapper">
                    {item.preview_image && (
                      <img
                        src={flattenToAppURL(
                          item?.preview_image.scales.preview.download,
                        )}
                        alt="preview meldung"
                      />
                    )}
                  </div>

                  <div className="meldungen-text-wrapper">
                    <h2>{item?.title}</h2>
                    <p>{item?.description}</p>
                  </div>
                </Link>
              )}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default MeldungenView;
