import React from 'react';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
const ColorBlock = ({ color, onChangeBlock, data, block }) => {
  /**
   * Align block handler
   * @method onColorBlock
   * @param {string} color Alignment option
   * @returns {undefined}
   */
  function onColorBlock(color) {
    onChangeBlock(block, {
      ...data,
      color,
    });
  }

  return (
    <div className="background-color-chooser">
      <Button.Group>
        <Button
          aria-label="White"
          onClick={() => onColorBlock('white')}
          active={data.color === 'white'}
          className={cx('white', { selected: data.color === 'white' })}
        />
      </Button.Group>
      <Button.Group>
        <Button
          aria-label="Blue"
          onClick={() => onColorBlock('blue')}
          active={data.color === 'blue'}
          className={cx('blue', { selected: data.color === 'blue' })}
        />
      </Button.Group>
      <Button.Group>
        <Button
          aria-label="Grey"
          onClick={() => onColorBlock('grey')}
          active={data.color === 'grey'}
          className={cx('grey', { selected: data.color === 'grey' })}
        />
      </Button.Group>
    </div>
  );
};

export default ColorBlock;
