/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import {
  settings as defaultSettings,
  views as defaultViews,
  widgets as defaultWidgets,
  blocks as defaultBlocks,
} from '@plone/volto/config';

import React from 'react';

// import {
//   SliderEditBlock,
//   SliderViewBlock,
//   TeaserGridEditBlock,
//   TeaserGridViewBlock,
//   ImagesGridEditBlock,
//   ImagesGridViewBlock,
//   ListingEditBlock,
//   ListingViewBlock,
//   TeaserHeroViewBlock,
//   TeaserHeroEditBlock,
// } from '@kitconcept/volto-blocks/components';

import { insertInArray } from '@kitconcept/volto-blocks/helpers';

import TalkeHeroViewBlock from '@package/components/Blocks/HeroBlock/View';
import TalkeHeroEditBlock from '@package/components/Blocks/HeroBlock/Edit';
import ContactViewBlock from '@package/components/Blocks/ContactBlock/View';
import ContactEditBlock from '@package/components/Blocks/ContactBlock/Edit';
import StaticContactViewBlock from '@package/components/Blocks/ContactBlockStatic/View';
import StaticContactEditBlock from '@package/components/Blocks/ContactBlockStatic/Edit';
import BenefitsViewBlock from '@package/components/Blocks/Benefits/View';
import BenefitsEditBlock from '@package/components/Blocks/Benefits/Edit';
import JobOffersViewBlock from '@package/components/Blocks/JobOffersBlock/View';
import JobOffersEditBlock from '@package/components/Blocks/JobOffersBlock/Edit';
import JobFilterViewBlock from '@package/components/Blocks/JobFilter/View.jsx';
import JobFilterEditBlock from '@package/components/Blocks/JobFilter/Edit.jsx';
import JobListingViewBlock from '@package/components/Blocks/JobListing/View.jsx';
import JobListingEditBlock from '@package/components/Blocks/JobListing/Edit.jsx';
import DoubleTextViewBlock from '@package/components/Blocks/DoubleTextBlock/View.jsx';
import DoubleTextEditBlock from '@package/components/Blocks/DoubleTextBlock/Edit.jsx';
import TalkeSliderEditBlock from '@package/components/Blocks/Aktuelles/Edit.jsx';
import TalkeSliderViewBlock from '@package/components/Blocks/Aktuelles/View.jsx';
import CookieChooserViewBlock from '@package/components/Blocks/CookieChooser/View.jsx';
import CookieChooserEditBlock from '@package/components/Blocks/CookieChooser/Edit.jsx';
import { MeldungenView, MeldungenEdit } from '@package/components';

import ImageLeft from '@plone/volto/icons/image-left.svg';
import colorPaletteSVG from '@plone/volto/icons/roller.svg';
import alignJustify from '@plone/volto/icons/align-justify.svg';
import hideSVG from '@plone/volto/icons/hide.svg';
import sliderSVG from '@plone/volto/icons/slider.svg';
import imagesSVG from '@plone/volto/icons/images.svg';
import listBulletSVG from '@plone/volto/icons/list-bullet.svg';
import heroSVG from '@plone/volto/icons/hero.svg';

import createInlineStyleButton from 'draft-js-buttons/lib/utils/createInlineStyleButton';
import Icon from '@plone/volto/components/theme/Icon/Icon';

export const views = {
  ...defaultViews,
};

export const widgets = {
  ...defaultWidgets,
};

const customBlocks = {
  // teaserGrid: {
  //   id: 'teaserGrid',
  //   title: 'Teaser grid',
  //   icon: imagesSVG,
  //   group: 'teasers',
  //   view: TeaserGridViewBlock,
  //   edit: TeaserGridEditBlock,
  //   restricted: false,
  //   mostUsed: true,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  // imagesGrid: {
  //   id: 'imagesGrid',
  //   title: 'Images grid',
  //   icon: imagesSVG,
  //   group: 'common',
  //   view: ImagesGridViewBlock,
  //   edit: ImagesGridEditBlock,
  //   restricted: false,
  //   mostUsed: true,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  // listing: {
  //   id: 'listing',
  //   title: 'Listing',
  //   icon: listBulletSVG,
  //   group: 'common',
  //   view: ListingViewBlock,
  //   edit: ListingEditBlock,
  //   restricted: false,
  //   mostUsed: true,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  // slider: {
  //   id: 'slider',
  //   title: 'Slider',
  //   icon: sliderSVG,
  //   group: 'common',
  //   view: SliderViewBlock,
  //   edit: SliderEditBlock,
  //   restricted: true,
  //   mostUsed: false,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  // teaserHero: {
  //   id: 'teaserHero',
  //   title: 'Teaser Hero',
  //   icon: heroSVG,
  //   group: 'teasers',
  //   view: TeaserHeroViewBlock,
  //   edit: TeaserHeroEditBlock,
  //   restricted: false,
  //   mostUsed: true,
  //   security: {
  //     addPermission: [],
  //     view: [],
  //   },
  // },
  talkeHero: {
    id: 'talkeHero',
    title: 'Talke Hero',
    icon: ImageLeft,
    group: 'text',
    view: TalkeHeroViewBlock,
    edit: TalkeHeroEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  meldungen: {
    id: 'meldungen',
    title: 'Meldungen',
    icon: sliderSVG,
    group: 'common',
    view: MeldungenView,
    edit: MeldungenEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  contact: {
    id: 'contact',
    title: 'Contact',
    icon: ImageLeft,
    group: 'text',
    view: ContactViewBlock,
    edit: ContactEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  staticContact: {
    id: 'staticContact',
    title: 'Static Contact',
    icon: ImageLeft,
    group: 'text',
    view: StaticContactViewBlock,
    edit: StaticContactEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  jobFilter: {
    id: 'jobFilter',
    title: 'Job Filter',
    icon: sliderSVG,
    group: 'jobs',
    view: JobFilterViewBlock,
    edit: JobFilterEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  jobListing: {
    id: 'jobListing',
    title: 'Job Listing',
    icon: sliderSVG,
    group: 'jobs',
    view: JobListingViewBlock,
    edit: JobListingEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  benefits: {
    id: 'benefits',
    title: 'Vorteile',
    icon: sliderSVG,
    group: 'text',
    view: BenefitsViewBlock,
    edit: BenefitsEditBlock,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  jobOffers: {
    id: 'jobOffers',
    title: 'Job Offers',
    icon: ImageLeft,
    group: 'jobs',
    view: JobOffersViewBlock,
    edit: JobOffersEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  doubleText: {
    id: 'doubleText',
    title: 'Double Text',
    icon: alignJustify,
    group: 'text',
    view: DoubleTextViewBlock,
    edit: DoubleTextEditBlock,
    restricted: false,
    mostUsed: true,
    blockHasOwnFocusManagement: true,
    security: {
      addPermission: [],
      view: [],
    },
  },
  talkeSlider: {
    id: 'talkeSlider',
    title: 'Slider',
    icon: sliderSVG,
    group: 'common',
    view: TalkeSliderViewBlock,
    edit: TalkeSliderEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
  cookieChooser: {
    id: 'cookieChooser',
    title: 'Cookie Auswahl',
    icon: hideSVG,
    group: 'common',
    view: CookieChooserViewBlock,
    edit: CookieChooserEditBlock,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  },
};

const newJobsGroup = { id: 'jobs', title: 'Jobs' };

const customGroupBlocksOrder = insertInArray(
  defaultBlocks.groupBlocksOrder,
  newJobsGroup,
  2,
);

const customStyleMap = {
  RED: {
    color: 'red',
  },
};

const customToHTMLrenderers = {
  RED: (children, { key }) => (
    <span className="color red" key={key}>
      {children}
    </span>
  ),
};

const RedButton = createInlineStyleButton({
  style: 'RED',
  children: <Icon name={colorPaletteSVG} size="24px" color={'Red'} />,
});

export const settings = {
  ...defaultSettings,
  richTextEditorInlineToolbarButtons: [
    RedButton,
    ...defaultSettings.richTextEditorInlineToolbarButtons,
  ],
  ToHTMLRenderers: {
    ...defaultSettings.ToHTMLRenderers,
    inline: {
      ...defaultSettings.ToHTMLRenderers.inline,
      ...customToHTMLrenderers,
    },
  },
  customStyleMap: customStyleMap,
  supportedLanguages: ['de'],
  defaultLanguage: 'de',
};

const {
  table,
  hero,
  html,
  ...newDefaultCustomBlocks
} = defaultBlocks.blocksConfig;

export const blocks = {
  ...defaultBlocks,
  requiredBlocks: [],
  blocksConfig: { ...newDefaultCustomBlocks, ...customBlocks },
  groupBlocksOrder: customGroupBlocksOrder,
};
