/**
 * App container.
 * @module components/theme/App/App
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { asyncConnect } from 'redux-connect';
import { Button, Container, Segment } from 'semantic-ui-react';
import Raven from 'raven-js';
import { renderRoutes } from 'react-router-config';
import { Slide, ToastContainer, toast } from 'react-toastify';
import cookie from 'react-cookie';
import cx from 'classnames';

import Error from '@plone/volto/error';

import {
  Footer,
  Header,
  Icon,
  Messages,
  Navigation,
} from '@plone/volto/components';
import { BodyClass, getBaseUrl, getView } from '@plone/volto/helpers';
import {
  getBreadcrumbs,
  getContent,
  getNavigation,
  getTypes,
  getWorkflow,
  purgeMessages,
} from '@plone/volto/actions';
import CookieModal from '@package/components/manage/Modals/CookieModal';

import clearSVG from '@plone/volto/icons/clear.svg';

import { updateIntl } from 'react-intl-redux';

import deLocale from '~/../locales/de.json';
import enLocale from '~/../locales/en.json';

const locales = {
  en: enLocale,
  de: deLocale,
};

/**
 * @export
 * @class App
 * @extends {Component}
 */
class App extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    purgeMessages: PropTypes.func.isRequired,
    updateIntl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      menuIsOpen: false,
      showConfirmModal: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu = () => {
    this.setState(state => ({
      menuIsOpen: !state.menuIsOpen,
    }));
  };

  /**
   * ComponentDidMount
   * @method ComponentDidMount
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  componentDidMount() {
    window._paq = window._paq || [];
    window._paq.push(['requireConsent']);
    if (__CLIENT__ && process.env.SENTRY_DSN) {
      Raven.config(process.env.SENTRY_DSN).install();
    }

    if (this.props.pathname === '/') {
      // Force lang to DE
      const language = 'de';
      cookie.save('lang', language, {
        expires: new Date((2 ** 31 - 1) * 1000),
        path: '/',
      });

      this.props.updateIntl({
        locale: language,
        messages: locales[language],
      });
      this.props.history.push(`/${language}`);
    }
  }

  /**
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (nextProps.pathname === '/') {
        let language =
          cookie.load('lang') || navigator.language || navigator.userLanguage;
        language = language.substring(0, 2);
        this.props.history.push(`/${language}`);
      }
      this.props.purgeMessages();

      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    }
  }

  /**
   * ComponentDidCatch
   * @method ComponentDidCatch
   * @param {string} error  The error
   * @param {string} info The info
   * @returns {undefined}
   */
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });
    if (__CLIENT__ && process.env.SENTRY_DSN) {
      Raven.captureException(error, { extra: info });
    }
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const path = getBaseUrl(this.props.pathname);
    const action = getView(this.props.pathname);

    return (
      <Fragment>
        <BodyClass className={`view-${action}view`} />
        <Navigation
          pathname={this.props.pathname}
          isOpen={this.state.menuIsOpen}
          toggleMenu={this.toggleMenu}
          mobile
        />
        <Header pathname={path} toggleMenu={this.toggleMenu} />
        <Segment basic className="sub-navigation header-wrapper static">
          <Container>
            <Navigation pathname={this.props.pathname} />
          </Container>
        </Segment>
        <Segment
          basic
          className={cx('content-area', {
            'third-layer-page': path.split('/').length >= 4,
          })}
        >
          <main>
            <Messages />
            {this.state.hasError ? (
              <Error
                message={this.state.error.message}
                stackTrace={this.state.errorInfo.componentStack}
              />
            ) : (
              renderRoutes(this.props.route.routes)
            )}
          </main>
        </Segment>
        <Footer />
        <ToastContainer
          position={toast.POSITION.BOTTOM_CENTER}
          hideProgressBar
          transition={Slide}
          closeButton={
            <Icon
              className="toast-dismiss-action"
              name={clearSVG}
              size="18px"
            />
          }
        />
        <CookieModal />
      </Fragment>
    );
  }
}

export const __test__ = connect(
  (state, props) => ({ pathname: props.location.pathname }),
  { purgeMessages },
)(App);

export default compose(
  asyncConnect([
    {
      key: 'breadcrumbs',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(getBreadcrumbs(getBaseUrl(location.pathname))),
    },
    {
      key: 'content',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(getContent(getBaseUrl(location.pathname))),
    },
    {
      key: 'navigation',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(getNavigation(getBaseUrl(location.pathname))),
    },
    {
      key: 'types',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(getTypes(getBaseUrl(location.pathname))),
    },
    {
      key: 'workflow',
      promise: ({ location, store: { dispatch } }) =>
        dispatch(getWorkflow(getBaseUrl(location.pathname))),
    },
  ]),
  connect((state, props) => ({ pathname: props.location.pathname }), {
    purgeMessages,
    updateIntl,
  }),
)(App);
