import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers';
import { getContent } from '@plone/volto/actions';

const AktuellesBody = props => {
  const { item, isEditMode } = props;
  const contentSubrequests = useSelector(state => state.content.subrequests);
  const dispatch = useDispatch();
  const blockID = item.id;
  const result = contentSubrequests?.[blockID]?.data;

  React.useEffect(() => {
    if (item.url) {
      dispatch(getContent(item.url, null, blockID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.url]);

  return (
    <div className="grid-teaser-item top">
      {result && (
        <>
          {(() => {
            const item = (
              <>
                {result?.preview_image && (
                  <div className="aktuelles-image-wrapper">
                    <img
                      src={flattenToAppURL(
                        result.preview_image.scales.preview.download,
                      )}
                      alt=""
                    />
                  </div>
                )}
                <div className="aktuelles-text-wrapper">
                  <h2>{result.nav_title || result.title}</h2>

                  {result.description && <p>{result.description}</p>}
                  <Link
                    to={flattenToAppURL(result['@id'])}
                    className="ui button fluid circular"
                  >
                    Mehr erfahren...
                  </Link>
                </div>
              </>
            );

            return item;
          })()}
        </>
      )}
    </div>
  );
};

export default AktuellesBody;
