/**
 * Navigation components.
 * @module components/theme/Navigation/Navigation
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import cx from 'classnames';
import { getBaseUrl } from '@plone/volto/helpers';
import { LanguageSelector } from '../../../../components';
import { getNavigation } from '@plone/volto/actions';
import { Icon } from '@plone/volto/components';
import closeSVG from '@package/icons/cancel.svg';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

/**
 * Navigation container class.
 * @class Navigation
 * @extends Component
 */
class Navigation extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    isOpen: PropTypes.bool,
    toggleMenu: PropTypes.func,
    mobile: PropTypes.bool,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
  constructor(props) {
    super(props);
    this.onToggleSubmenu = this.onToggleSubmenu.bind(this);
    this.state = {
      subMenuNumber: null,
    };
  }

  componentDidMount() {
    this.props.getNavigation(`/${this.props.lang}`, 2);
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.getNavigation(`/${this.props.lang}`, 2);
    }
  }

  /**
   * Check if menu is active
   * @method isActive
   * @param {string} url Url of the navigation item.
   * @returns {bool} Is menu active?
   */
  isActive(url) {
    return url === this.props.pathname;
  }

  onToggleSubmenu = subMenuNumber => {
    this.state.submenuOpen !== subMenuNumber
      ? this.setState({
          submenuOpen: subMenuNumber,
        })
      : this.setState({
          submenuOpen: null,
        });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */

  render() {
    return (
      <nav
        className={cx('navigation', {
          open: this.props.isOpen,
          'mobile only': this.props.mobile,
          'tablet computer large screen widescreen only': !this.props.mobile,
        })}
      >
        <Menu stackable pointing secondary onClick={this.closeMobileMenu}>
          <div className="mobile only tools-wrapper">
            <Icon name={closeSVG} size="25px" onClick={this.props.toggleMenu} />
          </div>
          <h2 className="mobile only">
            <FormattedMessage id="Carreer" defaultMessage="Carreer" />
          </h2>
          {this.props.items.map((item, index) => (
            <div
              className={cx('nav-item-wrapper', {
                'submenu-open': this.state.submenuOpen === index,
              })}
              key={index}
            >
              {index > 0 ? (
                <div>
                  <Link
                    to={item.url === 'de' ? '/' : item.url}
                    key={item.url}
                    className={cx('item', {
                      active:
                        this.isActive(item.url) ||
                        item.items?.some(subItem => this.isActive(subItem.url)),
                    })}
                    onClick={this.props.toggleMenu}
                  >
                    {item.title}
                  </Link>
                  {item.items?.length >= 1 && item.title !== 'Home' && (
                    <button
                      className="mobile only"
                      onClick={() => this.onToggleSubmenu(index)}
                    >
                      <i className="far fa-angle-right"></i>
                    </button>
                  )}
                </div>
              ) : (
                ''
              )}
              {item.items?.length >= 1 && item.title !== 'Home' && (
                <>
                  <div
                    className={cx('submenu-wrapper', {
                      open: this.state.submenuOpen === index,
                      active: item.items?.some(subItem =>
                        this.isActive(subItem.url),
                      ),
                    })}
                  >
                    <ul>
                      {item.items?.map(subItem => (
                        <li key={subItem.url}>
                          <Link
                            to={subItem.url === 'de' ? '/' : subItem.url}
                            className={
                              this.isActive(subItem.url)
                                ? 'item active'
                                : 'item'
                            }
                            onClick={this.props.toggleMenu}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="nav-item-wrapper last">
            <Link
              to="/de/stellenangebote"
              className={
                this.isActive('/de/stellenangebote') ? 'item active' : 'item'
              }
              onClick={this.props.toggleMenu}
            >
              Jobangebote
            </Link>
          </div>
        </Menu>
      </nav>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    state => ({
      items: state.navigation.items,
      lang: state.intl.locale,
    }),
    { getNavigation },
  ),
)(Navigation);
