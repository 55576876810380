import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Form, GridColumn } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { CheckboxWidget, Icon, TextWidget } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { settings } from '~/config';
import { ColorBlock } from '../../../helpers';

import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  Icon: {
    id: 'Show Image as Miniature Icon',
    defaultMessage: 'Show Image as Miniature Icon',
  },
  linkTitle: {
    id: 'Link Title',
    defaultMessage: 'Link Title',
  },
  title: {
    id: 'title',
    defaultMessage: 'title',
  },
  redButton: {
    id: 'Color Button Red',
    defaultMessage: 'Color Button Red',
  },

  titleBlock: {
    id: 'Show block as site title block',
    defaultMessage: 'Show block as site title block',
  },
  titleBlockDescription: {
    id:
      'Displaying this block as a page title block will show the heading in bold and change the mobile layout of the block to have the image cover the whole background of the block',
    defaultMessage:
      'Displaying this block as a page title block will show the heading in bold and change the mobile layout of the block to have the image cover the whole background of the block.',
  },
  mobileOnlyButton: {
    id: 'Only mobile',
    defaultMessage: 'Only mobile',
  },
  descriptionMobileOnlyButton: {
    id: 'Show this button only on smartphones and tablets',
    defaultMessage: 'Show this button only on smartphones and tablets',
  },
});

const HeroSidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [alt, setAlt] = useState(data.alt || '');

  return (
    <Segment.Group raised>
      <Segment className="form">
        <header className="header pulled">
          <h2>
            <FormattedMessage id="Layout" defaultMessage="Layout" />
          </h2>
        </header>
        <Form.Field inline required={required} className="help">
          <Grid>
            <Grid.Row>
              <Grid.Column width="4">
                <div className="wrapper">
                  <label htmlFor="field-align">
                    <FormattedMessage
                      id="Background Color"
                      defaultMessage=" Background Color:"
                    />
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column width="8" className="align-tools">
                <ColorBlock
                  color={data.color}
                  onChangeBlock={onChangeBlock}
                  data={data}
                  block={block}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <p className="help">
                  <FormattedMessage
                    id="Choose a background color for the Block"
                    defaultMessage="Choose a background color for the Block"
                  />
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </Segment>
      <Segment className="form sidebar-image-data">
        <header className="header pulled">
          <h2>
            <FormattedMessage id="Buttons" defaultMessage="Buttons" />
          </h2>
        </header>
        <TextWidget
          id="link"
          title={intl.formatMessage(messages.LinkTo)}
          required={false}
          value={data.href}
          icon={data.href ? clearSVG : navTreeSVG}
          iconAction={
            data.href
              ? () => {
                  onChangeBlock(block, {
                    ...data,
                    href: '',
                  });
                }
              : () => openObjectBrowser({ mode: 'link' })
          }
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="linkTitle"
          title={intl.formatMessage(messages.linkTitle)}
          required={false}
          value={data.linkTitle}
          icon={data.linkTitle && clearSVG}
          iconAction={() => {
            onChangeBlock(block, {
              ...data,
              linkTitle: '',
            });
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              linkTitle: value,
            });
          }}
        />
        <CheckboxWidget
          id="redButton"
          title={intl.formatMessage(messages.redButton)}
          value={data.redButton ? data.redButton : false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              redButton: value,
            });
          }}
        />
        <CheckboxWidget
          id="mobileOnlyButton"
          description={intl.formatMessage(messages.descriptionMobileOnlyButton)}
          title={intl.formatMessage(messages.mobileOnlyButton)}
          value={data.mobileOnlyButton ? data.mobileOnlyButton : false}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              mobileOnlyButton: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

HeroSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(HeroSidebar);
