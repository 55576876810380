import React from 'react';
import PropTypes from 'prop-types';

import View from './View.jsx';

const Benefits = props => {
  if (__SERVER__) {
    return <div />;
  } else {
    return (
      <>
        <View />
      </>
    );
  }
};

Benefits.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  onAddBlock: PropTypes.func.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onMutateBlock: PropTypes.func.isRequired,
  onFocusPreviousBlock: PropTypes.func.isRequired,
  onFocusNextBlock: PropTypes.func.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
};

export default Benefits;
