/**
 * View contact block.
 * @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';

/**
 * View contact block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className="block hero contact">
    <div className="block-inner-wrapper full-width">
      <div className="hero-image-container">
        {data.url && (
          <img
            src={`${flattenToAppURL(data.url)}/@@images/image/large`}
            alt=""
            className="hero-image"
          />
        )}
      </div>
      <div className="hero-body">
        <h2>
          <FormattedMessage id="Contact" defaultMessage="Contact" />
        </h2>
        <p>
          <FormattedMessage
            id="If you have any further questions, please do not hesitate to contact us:"
            defaultMessage="If you have any further questions, please do not hesitate to contact us: "
          />
        </p>
        <div className="contact-card">
          <p>{data.contactPerson} </p>
          <p>{data.division}</p>
          <p>{data.phoneNumber}</p>
        </div>
      </div>
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
