/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cookie from 'react-cookie';
import { connect } from 'react-redux';
import cx from 'classnames';
import request from 'superagent';
import { updateIntl } from 'react-intl-redux';

import deLocale from '~/../locales/de.json';
import enLocale from '~/../locales/en.json';

const locales = {
  en: enLocale,
  de: deLocale,
};

/**
 * LanguageSelector component class.
 * @class LanguageSelector
 * @extends Component
 */
@connect(
  state => ({
    lang: state.intl.locale,
  }),
  { updateIntl },
)
export default class LanguageSelector extends Component {
  static propTypes = {
    onClickAction: PropTypes.func,
  };

  static defaultProps = {
    onClickAction: () => {},
  };

  constructor(props) {
    super(props);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  /**
   * Change language handler
   * @method changeLanguage
   * @returns {undefined}
   */
  changeLanguage(language) {
    cookie.save('lang', language, {
      expires: new Date((2 ** 31 - 1) * 1000),
      path: '/',
    });

    this.props.updateIntl({
      locale: language,
      messages: locales[language],
    });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const { lang } = this.props;
    return (
      <div className="language-selector">
        <Link
          className={cx({ selected: lang === 'de' })}
          to="/de"
          onClick={() => {
            this.props.onClickAction();
            this.changeLanguage('de');
          }}
        >
          DE
        </Link>
        {' | '}
        <Link
          className={cx({ selected: lang === 'en' })}
          to="/en"
          onClick={() => {
            this.props.onClickAction();
            this.changeLanguage('en');
          }}
        >
          EN
        </Link>
      </div>
    );
  }
}
