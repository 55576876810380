/**
 * View contact block.
 * @module components/manage/Blocks/Hero/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import TeamImage from './Talke_HR_Team.jpg';
/**
 * View contact block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div className="block hero contact static">
    <div className="block-inner-wrapper full-width">
      <div className="hero-image-container container">
        <img src={TeamImage} alt="Talke Team" className="hero-image"></img>
      </div>
      <div className="hero-body">
        <h2>
          <FormattedMessage id="Contact" defaultMessage="Contact" />
        </h2>
        <p>
          Wenn Sie sich für eine Stelle in der TALKE-Gruppe interessieren,
          finden Sie hier{' '}
          <Link to="/de/stellenangebote">unsere aktuellen Stellenangebote</Link>
          .
        </p>
      </div>
    </div>
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
