import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Icon, TextWidget } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { settings } from '~/config';

import imageSVG from '@plone/volto/icons/image.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import navTreeSVG from '@plone/volto/icons/nav.svg';

const messages = defineMessages({
  Image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  Origin: {
    id: 'Origin',
    defaultMessage: 'Origin',
  },
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  Align: {
    id: 'Alignment',
    defaultMessage: 'Alignment',
  },
  LinkTo: {
    id: 'Link to',
    defaultMessage: 'Link to',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
  NoImageSelected: {
    id: 'No image selected',
    defaultMessage: 'No image selected',
  },
  externalURL: {
    id: 'External URL',
    defaultMessage: 'External URL',
  },
  Icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  linkTitle: {
    id: 'Link Title',
    defaultMessage: 'Link Title',
  },
  contactPerson: {
    id: 'Contact Person',
    defaultMessage: 'Contact Person',
  },
  division: {
    id: 'Division',
    defaultMessage: 'Division',
  },
  phoneNumber: {
    id: 'Phone Number',
    defaultMessage: 'Phone Number',
  },
});

const HeroSidebar = ({
  data,
  block,
  onChangeBlock,
  openObjectBrowser,
  required = false,
  intl,
}) => {
  const [alt] = useState(data.alt || '');

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Image" defaultMessage="Image" />
        </h2>
      </header>
      {!data.url && (
        <>
          <Segment className="sidebar-metadata-container" secondary>
            <FormattedMessage
              id="No image selected"
              defaultMessage="No image selected"
            />
            <Icon name={imageSVG} size="100px" color="#b8c6c8" />
          </Segment>
        </>
      )}
      {data.url && (
        <>
          <Segment className="sidebar-metadata-container" secondary>
            {data.url.split('/').slice(-1)[0]}
            {data.url.includes(settings.apiPath) && (
              <img
                src={`${flattenToAppURL(data.url)}/@@images/image/mini`}
                alt={alt}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <img src={data.url} alt={alt} style={{ width: '50%' }} />
            )}
          </Segment>
          <Segment className="form sidebar-image-data">
            {data.url.includes(settings.apiPath) && (
              <TextWidget
                id="Origin"
                title={intl.formatMessage(messages.Origin)}
                required={false}
                value={data.url.split('/').slice(-1)[0]}
                icon={navTreeSVG}
                iconAction={() => openObjectBrowser()}
                onChange={() => {}}
              />
            )}
            {!data.url.includes(settings.apiPath) && (
              <TextWidget
                id="external"
                title={intl.formatMessage(messages.externalURL)}
                required={false}
                value={data.url}
                icon={clearSVG}
                iconAction={() =>
                  onChangeBlock(block, {
                    ...data,
                    url: '',
                  })
                }
                onChange={() => {}}
              />
            )}
          </Segment>
        </>
      )}

      <Segment className="form sidebar-image-data">
        <TextWidget
          id="name"
          title={intl.formatMessage(messages.contactPerson)}
          required={false}
          value={data.contactPerson}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              contactPerson: value,
            });
          }}
        />
        <TextWidget
          id="division"
          title={intl.formatMessage(messages.division)}
          required={false}
          value={data.division}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              division: value,
            });
          }}
        />
        <TextWidget
          id="phoneNumber"
          title={intl.formatMessage(messages.phoneNumber)}
          required={false}
          value={data.phoneNumber}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              phoneNumber: value,
            });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

HeroSidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(HeroSidebar);
