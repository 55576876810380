/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Icon } from '@plone/volto/components';
import XingIcon from '@package/icons/xing-logo.svg';
import LinkedinIcon from '@package/icons/linkedin-logo.svg';
import KununuIcon from '@package/icons/kununu-logo.svg';
import YoutubeIcon from '@package/icons/youtube-logo.svg';
import FacebookIcon from '@package/icons/facebook-logo.svg';
import InstagramIcon from '@package/icons/instagram-logo.svg';
import TalkeLogo from '../Logo/Logo2024.png';
import LinkedInIcon from '@package/icons/linkedin-logo.svg';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => (
  <footer>
    <Segment role="contentinfo" vertical className="footer">
      <Container>
        <List horizontal link verticalAlign="middle" className="footer-text">
          <Link className="item" to="/de/impressum">
            <FormattedMessage id="Imprint" defaultMessage="Imprint" />
          </Link>
          <Link className="item" to="/de/datenschutzerklaerung">
            <FormattedMessage
              id="Privacy Policy"
              defaultMessage="Privacy Policy"
            />
          </Link>
          {/* <Link className="item" to="/contact-form">
            <FormattedMessage id="Contact" defaultMessage="Contact" />
          </Link> */}
        </List>
        <List horizontal link verticalAlign="middle" className="footer-icons">
          <a
            className="item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.xing.com/pages/talke"
          >
            <Icon name={XingIcon} size={null} />
          </a>
          {/* <a
            className="item"
            href="https://de.linkedin.com/company/alfred-talke-logistics-services-qatar"
          >
            <Icon name={LinkedinIcon} size={null} />
          </a> */}
          <a
            className="item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.kununu.com/de/alfred-talke"
          >
            <Icon name={KununuIcon} size={null} />
          </a>
          <a
            className="item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCPfQvKY5VrMHqEvJfkCu3Jw"
          >
            <Icon name={YoutubeIcon} size={null} />
          </a>
          {/* <a
            className="item"
            href="https://www.facebook.com/AlfredTALKECareers/"
          >
            <Icon name={FacebookIcon} size={null} />
          </a> */}
          <a
            className="item"
            href="https://de.linkedin.com/company/alfred-talke-logistics-services-qatar"
          >
            <Icon name={LinkedInIcon} size={null} />
          </a>
          <a
            className="item"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/talkekarriere/"
          >
            <Icon name={InstagramIcon} size={null} />
          </a>
        </List>
        <List horizontal link verticalAlign="middle" className="footer-logo">
          <a href="https://www.talke.com/">
            <Image src={TalkeLogo} alt="talke logo" height={45} />
          </a>
        </List>
      </Container>
    </Segment>
  </footer>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
