/**
 * File view component.
 * @module components/theme/View/FileView
 */

import React, { useState, useEffect } from 'react';
import request from 'superagent';
import { Container, Grid, Embed } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from '@plone/volto/helpers';
import { BodyClass } from '@plone/volto/helpers';
import { useParams } from 'react-router-dom';

/**
 * File view component class.
 * @function FileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const JobOfferView = ({ content }) => {
  const [loaded, setLoaded] = useState(false);
  const [job, setJob] = useState([]);

  let { jobOffer } = useParams();

  useEffect(() => {
    if (!loaded) {
      request
        .get(
          `https://talke.dvinci.de/portal/relaunch/jobPublication/show/${jobOffer}.json`,
        )
        .set('Accept', 'application/json')
        .then(res => {
          const newJob = {
            introduction: { __html: res.body.introduction },
            image: res.body.images[0].url,
            title: res.body.position,
            name: res.body.jobOpening.company.name,
            location: res.body.jobOpening.location,
            startDate: res.body.jobOpening.earliestEntryDate,
            workingTimes: res.body.jobOpening.workingTimes[0].name,
            task_headline: res.body.renderedContent.tasks.headline,
            task_body: { __html: res.body.renderedContent.tasks.body },
            profile_headline: res.body.renderedContent.profile.headline,
            profile_body: { __html: res.body.renderedContent.profile.body },
            weOffer_headline: res.body.renderedContent.weOffer.headline,
            weOffer_body: { __html: res.body.renderedContent.weOffer.body },
            contact_person: `${res.body.jobOpening.responsibleUser.salutation.name} ${res.body.jobOpening.responsibleUser.firstName} ${res.body.jobOpening.responsibleUser.lastName}`,
            contact_image: { __html: res.body.renderedContent.contact.body },
            contact_division: res.body.jobOpening.responsibleUser.division,
            contact_phoneNumber: res.body.jobOpening.responsibleUser.telephone,
            contact_street: `${res.body.jobOpening.responsibleUser.street} ${res.body.jobOpening.responsibleUser.number}`,
            contact_city: `${res.body.jobOpening.responsibleUser.zipCode} ${res.body.jobOpening.responsibleUser.city}`,
            benefits: {
              __html: res.body.renderedContent.weOffer.body,
            },
          };
          setJob(newJob);
        });
      setLoaded(true);
    }
  }, [job, jobOffer, loaded]);

  return (
    <Container className="view-wrapper" id="page-document">
      <BodyClass className="job-offer-view" />

      <Helmet title={job.title} />
      <div className="block image">
        <img
          src={job.image}
          alt={job.title}
          className="cover-image full-width"
        />
      </div>
      <div
        className="introduction"
        dangerouslySetInnerHTML={job.introduction}
      />
      <div className="block header-wrapper">
        <div className="block-header full-width ">
          <h1 className="documentFirstHeading">{job.title}</h1>
        </div>
      </div>
      <Grid columns={4} container>
        <Grid.Row className="job-profile">
          <Grid.Column computer={3} tablet={6} mobile={12}>
            <i className="fal fa-city"></i>
            <p>
              Unternehmen: <br />
              {job.name}
            </p>
          </Grid.Column>
          <Grid.Column computer={3} tablet={6} mobile={12}>
            <i className="fal fa-map-marker-alt"></i>
            <p>
              Ort: <br />
              {job.location}
            </p>
          </Grid.Column>
          <Grid.Column computer={3} tablet={6} mobile={12}>
            <i className="fal fa-calendar-alt"></i>
            <p>
              Beginn: <br />
              {job.startDate}
            </p>
          </Grid.Column>
          <Grid.Column computer={3} tablet={6} mobile={12}>
            <i className="fal fa-clock"></i>
            <p>
              Beschäftigungsart: <br />
              {job.workingTimes}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="job-description" columns={2}>
          <Grid.Column tablet={12} computer={6}>
            <h2>{job.task_headline}</h2>
            <div dangerouslySetInnerHTML={job.task_body} />
          </Grid.Column>
          <Grid.Column tablet={12} computer={6}>
            <h2>{job.profile_headline}</h2>
            <div dangerouslySetInnerHTML={job.profile_body} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid centered>
        <Grid.Row>
          <a
            href={`https://talke.dvinci.de/de/p/relaunch/jobs/${jobOffer}/apply/`}
            className="ui button circular primary"
          >
            Jetzt Bewerben
          </a>
        </Grid.Row>
      </Grid>
      <div className="block benefits">
        <div className="full-width">
          <Grid className="container" dangerouslySetInnerHTML={job.benefits} />
        </div>
      </div>

      <div className="block video align full">
        <div className="video-inner full-width">
          <Embed
            id="aylDej2ymCA"
            source="youtube"
            autoplay={true}
            brandedUI
            icon="fab fa-youtube"
            color="blue"
            placeholder="https://img.youtube.com/vi/aylDej2ymCA/maxresdefault.jpg"
          />
        </div>
      </div>
      <div className="block hero contact">
        <div className="block-inner-wrapper full-width">
          <div
            dangerouslySetInnerHTML={job.contact_image}
            className="hero-image-container"
          />
          <div className="hero-body">
            <h2>
              <FormattedMessage id="Contact" defaultMessage="Contact" />
            </h2>
            <div className="contact-card">
              <h3>{job.name}</h3>
              <p>{job.contact_person} </p>
              <p>{job.contact_division}</p>
              <p>{job.contact_phoneNumber}</p>
              <p>{job.contact_street}</p>
              <p>{job.contact_city}</p>
            </div>
          </div>
        </div>
      </div>
      <Grid centered>
        <Grid.Row>
          <a
            href={`https://talke.dvinci.de/de/p/relaunch/jobs/${jobOffer}/apply/`}
            className="ui button circular primary"
          >
            Jetzt Bewerben
          </a>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default JobOfferView;
