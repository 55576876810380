/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React, { Component } from 'react';
import { Container, Segment, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { Logo, Navigation } from '@plone/volto/components';

import { LanguageSelector } from '../../../../components';
import HamburgerSVG from '@package/icons/hamburger.svg';
import { Icon } from '@plone/volto/components';
import { Link } from 'react-router-dom';
import cx from 'classnames';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
export default class Header extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    token: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    toggleMenu: PropTypes.func.isRequired,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    token: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
  constructor(props) {
    super(props);
    this.state = {
      hasReachedTop: false,
    };
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <header className="main-header">
        <Segment basic className="header-wrapper">
          <Container>
            <div className="header">
              <div className="logo-nav-wrapper">
                <div className="logo">
                  <Logo />
                </div>
                <Icon
                  name={HamburgerSVG}
                  className="hamburger-button"
                  onClick={this.props.toggleMenu}
                  size="15px"
                  color="#0B3778"
                />
              </div>
              {this.props.pathname.includes('/stellenangebote/') ? (
                <a
                  href={`https://talke.dvinci.de/de/p/relaunch/jobs/${
                    this.props.pathname.split('/')[3]
                  }/apply`}
                  className="ui button circular primary"
                >
                  Jetzt Bewerben
                </a>
              ) : (
                <Link
                  to="/de/stellenangebote"
                  className="ui button circular primary"
                >
                  Finden Sie Ihre Stelle
                </Link>
              )}
            </div>
          </Container>
        </Segment>
      </header>
    );
  }
}
