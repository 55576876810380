/**
 * Edit Hero block.
 * @module components/manage/Blocks/Image/Edit
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import cx from 'classnames';
import withObjectBrowser from '@plone/volto/components/manage/Sidebar/ObjectBrowser';
import { createContent } from '@plone/volto/actions';
import { SidebarPortal } from '@plone/volto/components';
import DoubleTextSidebar from '../../manage/Sidebar/DoubleTextSidebar';
import { Link } from 'react-router-dom';
import { TextBody } from '@kitconcept/volto-blocks/components';

/**
 * Edit image block class.
 * @class Edit
 * @extends Component
 */
class Edit extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    selected: PropTypes.bool.isRequired,
    block: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    content: PropTypes.objectOf(PropTypes.any).isRequired,
    request: PropTypes.shape({
      loading: PropTypes.bool,
      loaded: PropTypes.bool,
    }).isRequired,
    pathname: PropTypes.string.isRequired,
    onChangeBlock: PropTypes.func.isRequired,
    onSelectBlock: PropTypes.func.isRequired,
    onDeleteBlock: PropTypes.func.isRequired,
    onFocusPreviousBlock: PropTypes.func.isRequired,
    onFocusNextBlock: PropTypes.func.isRequired,
    handleKeyDown: PropTypes.func.isRequired,
    createContent: PropTypes.func.isRequired,
    openObjectBrowser: PropTypes.func.isRequired,
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    if (__SERVER__) {
      return <div />;
    }
    return (
      <div
        className={cx(
          'block hero double-text',
          {
            selected: this.props.selected,
          },

          this.props.data.color,
        )}
      >
        <div className="block-inner-wrapper full-width">
          <div className="hero-body">
            <TextBody {...this.props} dataName="text-a" isEditMode />
          </div>
          <div className="hero-body">
            <TextBody {...this.props} dataName="text-b" isEditMode />

            {this.props.data.href && (
              <Link
                className={cx('ui button fluid circular', {
                  primary: this.props.data.redButton,
                })}
                to={this.props.data.href}
              >
                {this.props.data.linkTitle}
              </Link>
            )}
          </div>
        </div>
        <SidebarPortal selected={this.props.selected}>
          <DoubleTextSidebar {...this.props} />
        </SidebarPortal>
      </div>
    );
  }
}

export default compose(
  withObjectBrowser,
  connect(
    state => ({
      request: state.content.create,
      content: state.content.data,
    }),
    { createContent },
  ),
)(Edit);
