/**
 * View contact block.
 * @module components/manage/Blocks/Hero/View
 */

import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import request from 'superagent';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import JobOffer from './jobOffer';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

/**
 * View contact block class.
 * @class View
 * @extends Component
 */

const useQuery = () => new URLSearchParams(useLocation().search);

const View = ({ data }) => {
  const query = useQuery();
  const [loaded, setLoaded] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    if (!loaded) {
      request
        .get('https://talke.dvinci.de/portal/relaunch/jobPublication/list.json')
        .set('Accept', 'application/json')
        .then(res => {
          const newJobs = map(res.body, i => ({
            jobPublicationURL: i.jobPublicationURL,
            position: i.position,
            workingArea: map(i.jobOpening.categories, j => j.name),
            location: map(i.jobOpening.locations, j => j.name),
            entryLevel: map(i.jobOpening.targetGroups, j => j.name),
            date: i.jobOpening.earliestEntryDate,
            id: i.id,
          }));
          setJobs(newJobs);
        });
      setLoaded(true);
    }
  }, [jobs, loaded, query]);

  return (
    <div
      className={cx(
        'block hero job-offers',

        data.color,
      )}
    >
      <div className="block-inner-wrapper full-width">
        <div
          className={cx('hero-image-container', {
            icon: data.isIcon,
          })}
        >
          {data.url && (
            <img
              src={`${flattenToAppURL(data.url)}/@@images/image/large`}
              alt=""
              className="hero-image"
            />
          )}
        </div>
        <div className="hero-body">
          <h2>
            <FormattedMessage
              id="Free Jobs at Talke"
              defaultMessage="Free Jobs at Talke"
            />
          </h2>
          <p>
            <FormattedMessage
              id="{amount} free Jobs:"
              defaultMessage="{amount} free Jobs:"
              values={{ amount: jobs.length }}
            />
          </p>
          <ul>
            {jobs.length > 0 &&
              jobs.slice(0, data.jobAmount || 4).map(job => {
                return (
                  <li>
                    <JobOffer
                      title={job.position}
                      section={job.workingArea}
                      entranceLevel={job.entryLevel}
                      startDate={job.date}
                      location={job.location}
                      url={`/de/stellenangebote/${job.id}`}
                    />
                  </li>
                );
              })}
          </ul>
          <Link to="/de/stellenangebote" className="ui button fluid circular">
            <FormattedMessage id="To Job Board" defaultMessage="To Job Board" />
          </Link>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
