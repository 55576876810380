/**
 * View video block.
 * @module components/manage/Block/Video/View
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Embed, Button } from 'semantic-ui-react';
import cookie from 'react-cookie';

/**
 * View video block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  const [agreedYouTube, setAgreedYouTube] = useState(false);

  useEffect(() => {
    var confirmedYouTube = cookie.load('confirm_youtube');
    if (confirmedYouTube === 'confirmed') {
      setAgreedYouTube(true);
    }
  }, []);

  const agreeToYoutube = () => {
    var confirmedPrivacy = cookie.load('confirm_privacy');
    if (confirmedPrivacy !== 'confirmed') {
      cookie.save('confirm_youtube', 'confirmed', {
        expires: new Date((2 ** 31 - 1) * 1000),
        path: '/',
      });
    }
    setAgreedYouTube(true);
  };

  return (
    <div className="block video align full">
      {data.url && (
        <div className="video-inner full-width">
          {agreedYouTube ? (
            <Embed
              id={
                data.url.match(/.be\//)
                  ? data.url.match(/^.*\.be\/(.*)/)[1]
                  : data.url.match(/^.*\?v=(.*)$/)[1]
              }
              source="youtube"
              autoplay={true}
              brandedUI
              icon="fab fa-youtube"
              color="blue"
              placeholder={
                data.url.match(/.be\//)
                  ? `https://img.youtube.com/vi/${
                      data.url.match(/^.*\.be\/(.*)/)[1]
                    }/maxresdefault.jpg`
                  : `https://img.youtube.com/vi/${
                      data.url.match(/^.*\?v=(.*)$/)[1]
                    }/maxresdefault.jpg`
              }
            />
          ) : (
            <Button
              fluid
              className="youtube-agreement"
              onClick={() => agreeToYoutube()}
            >
              <img
                src={
                  data.url.match(/.be\//)
                    ? `https://img.youtube.com/vi/${
                        data.url.match(/^.*\.be\/(.*)/)[1]
                      }/maxresdefault.jpg`
                    : `https://img.youtube.com/vi/${
                        data.url.match(/^.*\?v=(.*)$/)[1]
                      }/maxresdefault.jpg`
                }
                alt=""
              ></img>
              <div className="youtube-text-backdrop">
                <div className="youtube-text">
                  <h3>Hinweis:</h3>
                  <p>
                    Das Aufrufen des nachstehenden Links zeigt Inhalte des
                    folgenden Kanals an und stellt zu diesem Zweck Verbindungen
                    zu den jeweiligen Servern her. Die ALFRED TALKE GmbH & Co.
                    KG macht sich die durch Links erreichbaren Webseiten Dritter
                    nicht zu eigen und ist für deren Inhalt nicht
                    verantwortlich. Die ALFRED TALKE GmbH & Co. KG hat keinen
                    Einfluss darauf, welche Ihrer personenbezogenen Daten ggf.
                    von den Betreibern dieser Seiten verarbeitet werden. Nähere
                    Informationen dazu geben die Anbieter der externen Webseiten
                    in ihren Datenschutzerklärungen. Das Aufrufen des hier
                    angebotenen Links zeigt Inhalte des folgenden Kanals an:{' '}
                    <a href={data.url}>{data.url}</a>
                  </p>
                  <i className="fab fa-youtube" />
                </div>
              </div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
