/**
 * Replace with custom client implementation when needed.
 * @module client
 */
import PiwikReactRouter from 'piwik-react-router';
import client, { history } from '@plone/volto/start-client';

client();

// Matomo integration (requireConsent is set in Html.jsx)
const piwik = PiwikReactRouter({
  url: 'https://matomo.karriere.talke.com',
  siteId: 1,
  serverTrackerName: 'matomo.php',
  clientTrackerName: 'matomo.js',
});
piwik.connectToHistory(history);

if (module.hot) {
  module.hot.accept();
}
