import React from 'react';
import JobFilter from './View';

const Edit = props => {
  return (
    <>
      <JobFilter />
    </>
  );
};

export default Edit;
