import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import Slider from 'react-slick';

import AktuellesBody from './AktuellesBody';
import leftArrowSVG from '@package/icons/left-arrow.svg';
import rightArrowSVG from '@package/icons/right-arrow.svg';

const PrevArrow = ({ className, style, onClick }) => (
  <Button className={className} onClick={onClick}>
    <i className="fal fa-angle-left"></i>
  </Button>
);

const NextArrow = ({ className, style, onClick }) => (
  <Button className={className} onClick={onClick}>
    <i className="fal fa-angle-right"></i>
  </Button>
);

const View = props => {
  const { data, isEditMode } = props;

  return (
    <div className="block aktuelles">
      {isEditMode && data.hrefList?.length < 1 && <h2> Slider </h2>}
      <div className="full-width">
        {data.title && <h2 className="slider-title">{data.title}</h2>}
        <Slider
          dots={true}
          infinite={data.hrefList?.length > 3}
          speed={500}
          slidesToShow={3}
          slidesToScroll={3}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          responsive={[
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {data.hrefList &&
            data.hrefList.map(item => (
              <AktuellesBody
                key={item.id}
                item={item}
                isEditMode={isEditMode}
              />
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default View;
